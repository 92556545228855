import React, { useEffect, useState } from 'react'
import { Input, Select, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import AppLayout from '../../components/app-layout/app-layout'
import { ContainerFilter, InputContainer, SearchTable } from './components'
import { tableColumns } from './tableColumns'
import useSwr from 'swr'
import { listMatriz } from '../../providers/api/accreditations'
import { statusTransactionsOptionsSelect } from '../../utils/status-transactions-options-select'
import { typeAdquirentOptionsSelect } from '../../utils/type-adquirent-options-select'

const TransactionsMonitoring = () => {
  const [searchValue, setSearchValue] = useState('')
  const [searchCNPJValue, setSearchCNPJValue] = useState('')
  const [selectValue, setSelectValue] = useState('')
  const [selectValueMatriz, setSelectValueMatriz] = useState('')
  const [selectValueTipoAdquirent, setSelectValueTipoAdquirent] = useState('')
  const [page, setPage] = useState(1)
  const [companies, setCompanies] = useState([])

  const {
    data: { data = [], currentPage = 1 } = {},
    mutate,
    isLoading,
    isValidating
  } = useSwr(
    `/accreditation/transactions?page=${page}&type=${selectValueTipoAdquirent}&cnpj=${searchCNPJValue.replace(/\D/g, '')}&matriz=${selectValueMatriz}&nomeCNPJ=${searchValue}&status=${selectValue}`
  )

  const { data: { count = 0, totalPages = 1 } = {} } = useSwr(
    `/accreditation/transactions/count?type=${selectValueTipoAdquirent}&cnpj=${searchCNPJValue.replace(/\D/g, '')}&matriz=${selectValueMatriz}&nomeCNPJ=${searchValue}&status=${selectValue}`
  )

  useEffect(() => {
    listMatriz().then(companies => {
      setCompanies(
        companies.map(company => ({
          value: company.id,
          label: company.companyName
        }))
      )
    })
  }, [])

  return (
    <Spin
      spinning={isLoading || isValidating}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <AppLayout
        openKeys={['transaction-monitoring']}
        currentOption="transaction-monitoring"
      >
        <h2>Monitoramento de transações</h2>
        <ContainerFilter>
          <InputContainer>
            <h4>Adquirente:</h4>
            <Select
              allowClear
              placeholder="Procurar por adquirente"
              options={typeAdquirentOptionsSelect}
              onChange={type => setSelectValueTipoAdquirent(type || '')}
            />
          </InputContainer>
          <InputContainer>
            <h4>Canal:</h4>
            <Select
              allowClear
              placeholder="Procurar por canal"
              options={companies}
              onChange={idMatriz => setSelectValueMatriz(idMatriz || '')}
            />
          </InputContainer>
          <InputContainer>
            <h4>CNPJ:</h4>
            <Input.Search
              placeholder="Procurar por CPF/CNPJ"
              value={searchCNPJValue}
              onSearch={value => {
                setSearchCNPJValue(value)
              }}
              onChange={e => setSearchCNPJValue(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <h4>Loja:</h4>
            <Input.Search
              placeholder="Procurar por Nome"
              value={searchValue}
              onSearch={value => {
                setSearchValue(value)
              }}
              onChange={e => setSearchValue(e.target.value)}
            />
          </InputContainer>
          <InputContainer>
            <h4>Status:</h4>
            <Select
              allowClear
              placeholder="Procurar por Status"
              options={statusTransactionsOptionsSelect}
              onChange={status => setSelectValue(status || '')}
            ></Select>
          </InputContainer>
        </ContainerFilter>
        <div>
          <h4>Total de transações: {count}</h4>
        </div>
        <SearchTable
          pagination={{
            simple: true,
            position: ['topRight'],
            defaultCurrent: 1,
            total: count,
            showSizeChanger: false,
            size: totalPages,
            current: currentPage,
            onChange: page => setPage(page)
          }}
          bordered
          rowClassName={() => 'editable-row'}
          dataSource={data || []}
          rowKey="id"
          columns={tableColumns({
            searchValue,
            searchCNPJValue,
            selectValue,
            refresh: mutate
          })}
          locale={{ emptyText: 'Nenhuma transação encontrada.' }}
        />
      </AppLayout>
    </Spin>
  )
}

export default TransactionsMonitoring
